//
// Google font - Poppins
//

// @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');
// @import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;0,800;0,900;1,400;1,600;1,700;1,800;1,900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Caveat&family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Dancing+Script&family=Fredericka+the+Great&family=Fredoka+One&family=Gochi+Hand&family=Indie+Flower&family=Koulen&family=Permanent+Marker&family=Poppins&family=Sacramento&family=Satisfy&family=Tenor+Sans&family=Varela+Round&family=ZCOOL+KuaiLe&display=swap");

// @font-face {
//   font-family: Circular;
//   src: url("circularstd-book.otf");
// }
